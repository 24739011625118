<template>
  <!-- Category search -->
  <el-dialog
    title="Бүтээгдэхүүн сонгох"
    :visible.sync="show"
    @close="closeDialog"
    width="600px"
  >
    <div>
      <el-input
        placeholder="Ундаа, ус ..."
        v-model="filterText"
        @input="searchProduct"
      >
        <el-button slot="prepend" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <div class="mt20" v-loading="loading">
      <div v-for="item in products" :key="item.supplier_id" class="border-top pt10 mb10">
        <el-row :gutter="20">
          <el-col :span="20">
            {{item._source.name_mon}}
          </el-col>
          <el-col :span="4">
            <div class="text-right">
              <el-checkbox v-model="item.checked"></el-checkbox>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">Cancel</el-button>
      <el-button type="success" @click="returnProducts">
        Бүтээгдэхүүн сонгох
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import services from '../../../../helpers/services'
export default {
  name: 'bannerSearchProductDialog',
  props: [
    'visibleProductDialog'
  ],
  data () {
    return {
      filterText: '',
      loading: false,
      products: [],
      show: this.visibleProductDialog
    }
  },
  methods: {
    searchProduct () {
      if (this.filterText.length > 2) {
        this.loading = true
        services.getSearchProductName(this.filterText).then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            response.data.forEach(product => {
              const property = {
                checked: false
              }
              Object.assign(product, property)
            })
            this.products = response.data
          }
          this.loading = false
        })
      }
    },
    closeDialog () {
      this.$emit('update:visibleProductDialog', false)
    },
    returnProducts () {
      const tempProducts = [...this.products.filter(product => product.checked)]
      if (tempProducts.length > 0) {
        this.$emit('update:products', tempProducts)
        this.show = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Бүтээгдэхүүнээ сонгоно уу',
          duration: 2000
        })
      }
    }
  }
}
</script>
