<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" @click="saveBanner('bannerForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-loading="loading">
      <el-col :span="20" :offset="2">
          <custom-header :title="'Сурталчилгаа үүсгэх'"/>
        <el-form label-position="top" :model="bannerForm" :rules="bannerRules" ref="bannerForm">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол гарчиг" prop="title">
                        <el-input v-model="bannerForm.title" placeholder="Сурталчилгааны монгол гарчиг ..."></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Англи гарчиг" prop="title_eng">
                        <el-input v-model="bannerForm.title_eng" placeholder="Сурталчилгааны англи гарчиг..."></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                      <el-form-item label="Монгол тайлбар" prop="description">
                        <el-input v-model="bannerForm.description" placeholder="Ток ток аппын сурталчилгаа ..." class="block">
                        </el-input>
                      </el-form-item>
                       <el-form-item label="Англи тайлбар">
                        <el-input v-model="bannerForm.description_eng" placeholder="Ток ток app ..." class="block">
                        </el-input>
                      </el-form-item>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Сурталчилгаа харуулах хуудас">
                        <el-select v-model="bannerForm.page" placeholder="Нүүр хуудас..." class="block" @change="onChangePage">
                          <el-option
                            v-for="page in bannerPage"
                            :key="page.value"
                            :label="page.label"
                            :value="page.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12" class="mt20">
                  <div v-if="this.bannerForm.page === 'product'">
                      <div class="text-right">
                        <el-button
                          icon="el-icon-search"
                          @click="visibleProductDialog = true"
                        >Бүтээгдэхүүн сонгох</el-button>
                      </div>
                      <div v-if="products.length > 0">
                        <div v-for="product in products" :key="product.productId">
                            <el-row :gutter="5" type="flex" align="middle" class="mt10 pt10 border-top">
                              <el-col :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                                <div class="image-holder">
                                  <img :src="product._source.image[0]" alt="">
                                </div>
                              </el-col>
                              <el-col :xl="16" :lg="16" :md="14" :sm="14" :xs="14">
                                <div class="text-color-secondary">{{product._source.name_mon}}</div>
                                <!-- <div>{{variant.variant_name}}</div> -->
                                <!-- <div class="text-color-placeholder">{{variant.variant_sku}}</div> -->
                              </el-col>
                              <el-col :xl="4" :lg="4" :md="4" :sm="4" :xs="4">
                                <div class="text-right">
                                  <i class="el-icon-close cursor-pointer" @click="deleteVariantFromDiscount(product)"></i>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                      </div>
                    </div>
                  <div v-if="this.bannerForm.page === 'brand'">
                      <div class="text-right">
                        <el-button icon="el-icon-search" @click="visibleBrandDialog = true">Брэнд сонгох</el-button>
                      </div>
                      <div v-if="brands.length > 0">
                        <div v-for="brand in brands" :key="brand.brand_id">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="20">
                              {{brand.brand_monName}}
                            </el-col>
                            <el-col :span="4">
                              <div class="text-right">
                                <i class="el-icon-close cursor-pointer" @click="removeBrand(item)"></i>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="this.bannerForm.page === 'category'" class="mt5">
                      <el-input
                        placeholder="Эрэгтэй, эмэгтэй, гутал ..."
                        v-model="treeFilterText"
                      >
                        <el-button slot="prepend" icon="el-icon-search"></el-button>
                      </el-input>
                      <el-tree
                        :data="categories"
                        :props="treeDefaultProps"
                        :filter-node-method="filterNode"
                        show-checkbox
                        ref="tree"
                        class="mt20">
                      </el-tree>
                    </div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Сурталчилгааны байрлал" prop="position">
                        <el-select placeholder="Сурталчилгааны байрлал сонгох" class="block" v-model="bannerForm.position" @change="onChangeSize">
                          <el-option
                            v-for="type in bannerPositionType"
                            :key="type.value"
                            :label="type.label"
                            :value="type.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Сурталчилгааны төрөл" prop="type">
                        <el-select placeholder="Сурталчилгааны төрлөө сонгоно уу" class="block" v-model="bannerForm.type">
                           <el-option
                              v-for="type in bannerType"
                              :key="type.value"
                              :label="type.label"
                              :value="type.value">
                            </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Веб шилжих хуудас" v-if="bannerForm.type === 'DEEP_LINKING'">
                        <el-select class="block" placeholder="Шилжих хуудсаа сонгоно уу ..." v-model="bannerForm.pass_prop">
                          <el-option
                            v-for="type in bannerSubType"
                            :key="type.value"
                            :label="type.label"
                            :value="type.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="Шилжих веб хуудас url:" v-if="bannerForm.type === 'WEB_LINKING'">
                        <el-input v-model="web_url" placeholder="www.gogo.mn ..."></el-input>
                      </el-form-item>
                      <div  v-if="bannerForm.type === 'POP_UP'">
                        <el-form-item label="Pop Up зураг url:">
                          <el-input v-model="pop_up_image_url" placeholder="www.gogo.mn ..."></el-input>
                        </el-form-item>
                        <el-form-item label="Pop Up сурталчилгааны тайлбар:">
                          <el-input v-model="pop_up_description"></el-input>
                        </el-form-item>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="Эхлэх огноо" prop="start_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="bannerForm.start_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Дуусах огноо" prop="end_date">
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          v-model="bannerForm.end_date"
                          type="datetime"
                          placeholder="Огноо сонгоно уу">
                        </el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Зураг">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :auto-upload="false"
                          :file-list="tempImagesMon"
                          :on-remove="(file) => handleRemove(file, 'mon')"
                          :on-change="(file) => getFile(file, 'mon', width, height)">
                          <span v-if="this.width !== null">{{this.width}}x{{this.height}}</span>
                          <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="isUploading" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Зураг англи">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          action=""
                          :auto-upload="false"
                          :file-list="tempImagesEng"
                          :on-remove="(file) => handleRemove(file, 'eng')"
                          :on-change="(file) => getFile(file, 'eng', width, height)">
                          <span v-if="this.width !== null">{{this.width}}x{{this.height}}</span>
                          <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <loader :active="isUploadingEng" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <el-form-item label="Сурталчилгааны төлөв" prop="status">
                    <el-select v-model="bannerForm.status" placeholder="Идэвхтэй ..." class="block">
                      <el-option
                        v-for="status in bannerStatus"
                        :key="status.value"
                        :label="status.label"
                        :value="status.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="SortNumber">
                    <el-input v-model="bannerForm.sortNumber"></el-input>
                   </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="saveBanner('bannerForm')">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
        <brand-search-dialog v-if="visibleBrandDialog" :visible-brand-dialog.sync="visibleBrandDialog" :brands.sync="brands"></brand-search-dialog>
        <product-search-dialog v-if="visibleProductDialog" :visible-product-dialog.sync="visibleProductDialog" :products.sync="products"></product-search-dialog>
      </el-col>
    </el-row>
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/banner-list">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import loader from '../../../components/common/loader'
import { handlers } from '../../../helpers/custom'
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader.vue'
import BrandSearchDialog from '../discount/components/brandSearchDialog'
import ProductSearchDialog from './components/bannerSearchProductDialog'
const bannerPositions = [{
  value: 'left-top',
  label: 'Зүүн дээр'
}, {
  value: 'right-top',
  label: 'Баруун дээр'
}, {
  value: 'right-bottom',
  label: 'Баруун доор'
}, {
  value: 'top',
  label: 'Дээр'
}, {
  value: 'middle-right',
  label: 'Баруун дунд'
}, {
  value: 'middle-left',
  label: 'Зүүн дунд'
}, {
  value: 'middle',
  label: 'Дунд'
}]

export default {
  name: 'addBanner',
  components: {
    customHeader,
    loader,
    BrandSearchDialog,
    ProductSearchDialog
  },

  data () {
    return {
      width: null,
      height: null,
      tempImagesMon: [],
      tempImagesEng: [],
      visibleBrandDialog: false,
      visibleProductDialog: false,
      isUploading: false,
      isUploadingEng: false,
      loading: false,
      confirmLeaveForm: false,
      treeFilterText: '',
      treeDefaultProps: {
        children: 'childrens',
        label: 'name_mon'
      },
      pop_up_image_url: '',
      pop_up_description: '',
      categories: [],
      brands: [],
      products: [],
      web_url: '',
      bannerForm: {
        brand_id: '',
        product_id: '',
        category_id: '',
        description: '',
        description_eng: '',
        sortNumber: '',
        status: '',
        image_url: '',
        image_url_eng: '',
        page: '',
        type: '',
        pass_prop: [],
        position: '',
        title: '',
        title_eng: '',
        start_date: new Date(),
        end_date: new Date()
      },

      bannerPage: [{
        value: 'home',
        label: 'Нүүр хуудас'
      }, {
        value: 'product',
        label: 'Бүтээгдэхүүн'
      }, {
        value: 'brand',
        label: 'Брэнд'
      }, {
        value: 'brands',
        label: 'Брэндүүд'
      }, {
        value: 'category',
        label: 'Категори'
      }, {
        value: 'daily_deals',
        label: 'Онцлох'
      }, {
        value: 'coupon',
        label: 'Купон'
      }, {
        value: 'co_working',
        label: 'Хамтран ажиллах'
      }],

      bannerPositionType: bannerPositions,

      bannerSubType: [{
        value: 'home_page',
        label: 'Нүүр хуудас'
      }, {
        value: 'Option2',
        label: 'test'
      }],

      bannerStatus: [{
        value: 1,
        label: 'Идэвхтэй'
      }, {
        value: 0,
        label: 'Идэвхгүй'
      }],

      bannerType: [{
        value: 'IMAGE',
        label: 'Зураг'
      }, {
        value: 'WEB_LINKING',
        label: 'Веб холбоостой'
      }, {
        value: 'DEEP_LINKING',
        label: 'Веб дотоод холбоостой'
      }, {
        value: 'POP_UP',
        label: 'POP_UP холбоостой'
      }],

      bannerRules: {
        title: [
          { required: true, message: 'Сурталчилгааны монгол гарчиг оруулна уу', trigger: 'blur' }
        ],
        title_eng: [
          { required: true, message: 'Сурталчилгааны англи гарчиг оруулна уу', trigger: 'blur' }
        ],
        position: [
          { required: true, message: 'Сурталчилгааны байрлал сонгоно уу', trigger: 'blur' }
        ],
        type: [
          { required: true, message: 'Сурталчилгааны төрөл сонгоно уу', trigger: 'blur' }
        ],
        start_date: [
          { required: true, message: 'Эхлэх огноогоо сонгоно уу', trigger: 'blur' }
        ],
        end_date: [
          { required: true, message: 'Дуусах огноогоо сонгоно уу', trigger: 'blur' }
        ],
        image_url: [
          { required: true, message: 'Зураг оруулна уу', trigger: 'blur' }
        ],
        status: [
          { required: true, message: 'Төлөв сонгоно уу', trigger: 'blur' }
        ],
        description: [
          { required: true, message: 'Тайлбар оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  watch: {
    treeFilterText (val) {
      this.$refs.tree.filter(val)
    }
  },

  created () {
    this.getCategories()
  },

  methods: {
    onChangeSize (position, page = this.bannerForm.page) {
      if (page === 'home' && position === 'left-top') {
        this.width = 840
        this.height = 560
      } else if (page === 'home' && position === 'right-top' ? page === 'home' && position === 'right-top' : page === 'home' && position === 'right-bottom') {
        this.width = 400
        this.height = 260
      } else if (page === 'product' && position === 'right-top' ? page === 'product' && position === 'right-top' : page === 'product' && position === 'right-bottom') {
        this.width = 250
        this.height = 250
      } else if (page === 'brand' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'brands' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'category' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'category' && position === 'middle-right' ? page === 'category' && position === 'middle-right' : page === 'category' && position === 'middle-left') {
        this.width = 500
        this.height = 400
      } else if (page === 'daily_deals' && position === 'left-top') {
        this.width = 840
        this.height = 560
      } else if (page === 'daily_deals' && position === 'right-top' ? page === 'daily_deals' && position === 'right-top' : page === 'daily_deals' && position === 'right-bottom') {
        this.width = 400
        this.height = 260
      } else if (page === 'coupon' && position === 'top') {
        this.width = 1280
        this.height = 280
      } else if (page === 'co_working' && position === 'top') {
        this.width = 1091
        this.height = 431
      } else if (page === 'co_working' && position === 'middle') {
        this.width = 600
        this.height = 400
      }
    },

    onChangePage (page) {
      if (page === 'home') {
        const tempData = bannerPositions.slice(0, 3)
        this.bannerPositionType = tempData
      } else if (page === 'product') {
        const tempData = bannerPositions.slice(1, 3)
        this.bannerPositionType = tempData
      } else if (page === 'brand') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'brands') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'category') {
        const tempData = bannerPositions.slice(3, 6)
        this.bannerPositionType = tempData
      } else if (page === 'daily_deals') {
        const tempData = bannerPositions.slice(0, 3)
        this.bannerPositionType = tempData
      } else if (page === 'coupon') {
        const tempData = bannerPositions.slice(3, 4)
        this.bannerPositionType = tempData
      } else if (page === 'co_working') {
        const tempData = [bannerPositions[3], bannerPositions[6]]
        this.bannerPositionType = tempData
      }
    },

    getCategories () {
      this.loading = true
      services.getCategoriesAsTree().then(data => {
        this.categories = data
        this.loading = false
      })
    },

    handleRemove (file, type) {
      var tempId = 0
      if (type === 'mon') {
        this.tempImagesMon.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesMon.splice(tempId, 1)
      } else if (type === 'eng') {
        this.tempImagesEng.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesEng.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    getFile (file, type, width, height) {
      handlers.checkImageSize(file, true, width, height).then(() => {
        if (type === 'mon') {
          this.isUploading = true
        } else if (type === 'eng') {
          this.isUploadingEng = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'mon') {
              this.isUploading = false
            } else if (type === 'eng') {
              this.isUploadingEng = false
            }
            if (response.status === 'success') {
              if (type === 'mon') {
                this.tempImagesMon.push({
                  url: response.url
                })
              } else if (type === 'eng') {
                this.tempImagesEng.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    removeBrand (val) {
      this.brands = this.brands.filter(brand => brand !== val)
    },

    deleteVariantFromDiscount (val) {
      this.products = this.products.filter(product => product !== val)
    },

    filterNode (value, data) {
      if (!value) return true
      return data.name_mon.indexOf(value) !== -1
    },

    saveBanner (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.tempImagesMon.forEach(element => {
            this.bannerForm.image_url = element.url
          })
          this.tempImagesEng.forEach(element => {
            this.bannerForm.image_url_eng = element.url
          })
          var tempFormData = JSON.parse(JSON.stringify(this.bannerForm))
          this.loading = true
          if (tempFormData.page === 'home' || tempFormData.page === 'daily_deals' || tempFormData.page === 'coupon' || tempFormData.page === 'co_working') {
            tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
          } else if (tempFormData.page === 'product') {
            this.products.forEach(product => {
              tempFormData.product_id = product._source.productId
              tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
            })
          } else if (tempFormData.page === 'brand') {
            this.brands.forEach(brand => {
              tempFormData.brand_id = brand.brand_id
              tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
            })
          } else if (tempFormData.page === 'category') {
            const checkedNodes = this.$refs.tree.getCheckedNodes()
            checkedNodes.forEach(category => {
              tempFormData.category_id = category.uniq_id
              tempFormData.sortNumber = parseInt(this.bannerForm.sortNumber)
            })
          }
          tempFormData.pass_prop.push(
            {
              web_url: this.web_url,
              pop_up_image_url: this.pop_up_image_url,
              pop_up_description: this.pop_up_description
            }
          )
          services.addBanners(tempFormData).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', response.message, 'success')
              this.loading = false
              this.$router.push({ name: 'bannerList' })
            } else if (response.status === 'unsuccess') {
              this.alertReporter('Алдаа гарлаа', response.message, 'unsuccess')
              this.loading = false
            }
          })
        }
      })
    }
  }
}
</script>

<style>

</style>
